import React from 'react';
import {
  Header,
  HeaderContainer,
  HeaderName,
  HeaderNavigation,
  HeaderMenuButton,
  HeaderMenuItem,
  HeaderGlobalBar,
  HeaderGlobalAction,
  SkipToContent,
  SideNav,
  SideNavItems,
  HeaderSideNavItems,
} from '@carbon/react';

import { Switcher, Notification, UserAvatar } from '@carbon/react/icons';
import { Link, useNavigate } from 'react-router-dom';

import { useAuthenticator, Button, Heading, View } from '@aws-amplify/ui-react';
import logo from '../../assets/logo.png';

const TutorialHeader = () => {
  const { signOut } = useAuthenticator(context => [context.signOut]);
  const navigate = useNavigate();

  function logOut() {
    signOut();
    navigate('/login');
  }

  return (
    <HeaderContainer
      render={({ isSideNavExpanded, onClickSideNavExpand }) => (
        <Header aria-label="Carbon Tutorial">
          <SkipToContent />
          <HeaderMenuButton
            aria-label="Open menu"
            onClick={onClickSideNavExpand}
            isActive={isSideNavExpanded}
          />

          <HeaderName element={Link} to="/" prefix="">
            {/*Tring*/}
            <img src={logo} className="App-logo" alt="logo" />
          </HeaderName>
          <HeaderNavigation aria-label="Carbon Tutorial">
            <HeaderMenuItem href="/" type="icon">
              Home
            </HeaderMenuItem>
            <HeaderMenuItem href="/hostmeeting">Host</HeaderMenuItem>
            <HeaderMenuItem href="/joinmeeting">Join</HeaderMenuItem>
            <HeaderMenuItem href="#" onClick={logOut}>
              Logout
            </HeaderMenuItem>
          </HeaderNavigation>
          <SideNav
            aria-label="Side navigation"
            expanded={isSideNavExpanded}
            isPersistent={false}>
            <SideNavItems>
              <HeaderSideNavItems>
                <HeaderMenuItem href="/" type="icon">
                  Home
                </HeaderMenuItem>
                <HeaderMenuItem href="/hostmeeting">Host</HeaderMenuItem>
                <HeaderMenuItem href="/joinmeeting">Join</HeaderMenuItem>
                <HeaderMenuItem href="#" onClick={logOut}>
                  Logout
                </HeaderMenuItem>
              </HeaderSideNavItems>
            </SideNavItems>
          </SideNav>
          <HeaderGlobalBar>
            <HeaderGlobalAction
              aria-label="Notifications"
              tooltipAlignment="center">
              <Notification size={20} />
            </HeaderGlobalAction>
            <HeaderGlobalAction
              aria-label="User Avatar"
              tooltipAlignment="center">
              <UserAvatar size={20} />
            </HeaderGlobalAction>
            <HeaderGlobalAction
              aria-label="App Switcher"
              tooltipAlignment="end">
              <Switcher size={20} />
            </HeaderGlobalAction>
          </HeaderGlobalBar>
        </Header>
      )}
    />
  );
};

export default TutorialHeader;
