// write a function that will take the user to the next screen
// and pass the meeting name to the next screen

import { useEffect, useState } from 'react';
// import from carbon react components
import {
  TextInput,
  TextArea,
  Button,
  Grid,
  Column,
  Form,
  FormGroup,
  Stack,
  Row,
} from '@carbon/react';
import {
  VideoChat,
  Login,
  GroupPresentation,
  ResetAlt,
} from '@carbon/react/icons';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router';

const HostMeeting = () => {
  const [meetingName, setMeetingName] = useState('');
  const [meetingDescription, setMeetingDescription] = useState('');
  //check if session is created
  const [sessionCreated, setSessionCreated] = useState(false);
  //store the session id
  const [sessionId, setSessionId] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // setSessionCreated(true);

    if (sessionId != '' && sessionCreated) {
      console.log(
        'Navigating from Hosting Screen to Joining Screen',
        sessionId
      );
      navigate('/joinmeeting/' + sessionId, {
        state: {
          meetingName: meetingName,
          sessionId: sessionId,
          meetingDescription: meetingDescription,
        },
      });
    }

    return () => {
      console.log('un Loading Hosting Screen', sessionId);
    };
  }, [sessionId, sessionCreated]);

  const handleHostMeeting = async () => {
    //get the user's username
    const user = await Auth.currentAuthenticatedUser();
    const username = user.username;
    // console.log(user);

    //post data to the backend api
    const resp = await fetch('https://dev-api.opentring.io/items/getsession', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        title: meetingName,
        description: meetingDescription,
        roomID: username,
      }),
    });
    const data = await resp.json();
    console.log(data);

    console.log('Setting session id ', data.session_id);
    setSessionId(data.session_id);
    setSessionCreated(true);
    //
    // const params = {
    //   meetingName: meetingName,
    //   sessionId: sessionId
    // }
    console.log('Returning');
    return;
  };

  const handleCancel = () => {
    setSessionCreated(false);
    setMeetingName('');
    setMeetingDescription('');
    setSessionId('');
  };
  return (
    <Grid>
      <Column lg={16} className="host-meetng__banner">
        <h1 className="host-meetng__heading">Host a Meeting</h1>
      </Column>
      {!sessionCreated && (
        <Column lg={8} className="host-meetng__r2">
          <FormGroup legendText="">
            <Stack gap={7}>
              <TextInput
                id="meeting-name"
                labelText="Meeting Title"
                placeholder="Enter title or description of meeting"
                value={meetingName}
                onChange={e => setMeetingName(e.target.value)}
              />
              <TextArea
                id="meeting-description"
                labelText="Meeting Description"
                placeholder="Enter a description of the meeting"
                value={meetingDescription}
                onChange={e => setMeetingDescription(e.target.value)}
              />
              <Button onClick={handleHostMeeting} renderIcon={VideoChat}>
                Host Meeting
              </Button>
            </Stack>
          </FormGroup>
        </Column>
      )}
    </Grid>
  );
};

export default HostMeeting;
// use the navigation prop to navigate to the next screen
