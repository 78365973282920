// write a function that will take the user to the next screen
// and pass the meeting name to the next screen

import { useEffect, useState } from 'react';
// import from carbon react components
import {
  TextInput,
  TextArea,
  Button,
  Grid,
  Column,
  Form,
  FormGroup,
  Stack,
  Row,
  Select,
  SelectItem,
} from '@carbon/react';
import {
  VideoChat,
  Login,
  GroupPresentation,
  ResetAlt,
} from '@carbon/react/icons';
import { Auth } from 'aws-amplify';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useLocation, useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';

import { OpenVidu } from 'openvidu-browser';
import UserModel from '../../models/user-model';

const JoinMeeting = props => {
  console.log(props);

  const [meetingName, setMeetingName] = useState('');
  const [meetingDescription, setMeetingDescription] = useState('');
  //check if session is created
  const [sessionCreated, setSessionCreated] = useState(false);
  //store the session id
  const { route } = useAuthenticator(context => [context.route]);
  const llocaion = useLocation();

  const { sessionId } = useParams();

  const [userName, setUserName] = useState('');
  const [OV, setOV] = useState(null);
  const [session, setSession] = useState(null);
  const [token, setToken] = useState(null);
  const [subscribers, setSubscribers] = useState([]);
  const [user, setUser] = useState(null);
  const [localUser, setLocalUser] = useState(new UserModel());
  const [localStreamManager, setLocalStreamManager] = useState(null);
  const [publisher, setPublisher] = useState(null);
  const [devices, setDevices] = useState([]);
  const [selectedCamera, setSelectedCamera] = useState(null);
  const [selectedMic, setSelectedMic] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (llocaion.state != null) {
      setMeetingName(llocaion.state.meetingName);
      setMeetingDescription(llocaion.state.meetingDescription);
      setSessionCreated(true);
      setOV(new OpenVidu());
    }

    return () => {
      console.log('UnLoading Joining Screen');
    };
  }, [sessionId, llocaion]);

  useEffect(() => {
    (async () => {
      const user = await Auth.currentAuthenticatedUser();
      const username = user.username;
      setUserName(username);
      console.log('OV', OV);
      if (OV != null) {
        let session = OV.initSession();
        setSession(session);
        console.log('session', session);
        let devicesList = await OV.getDevices();
        setDevices(devicesList);
        console.log('devices', devicesList);
      }
    })();

    return () => {
      setUserName('');
    };
  }, [OV, llocaion]);

  function subscribeToStreamCreated() {
    session.on('streamCreated', event => {
      console.log('streamCreated', event);
      let subscriber = session.subscribe(event.stream, 'subscriber', {
        insertMode: 'APPEND',
        width: '100%',
        height: '100%',
      });
      const newUser = new UserModel();
      newUser.setStreamManager(subscriber);
      newUser.setConnectionId(event.stream.connection.connectionId);
      newUser.setType('remote');
      const nickname = event.stream.connection.data.split('%')[0];
      newUser.setNickname(JSON.parse(nickname).clientData);
      setSubscribers([...subscribers, newUser]);
    });
  }

  function connectToSession() {
    console.log('connectToSession', token);
    if (token != null) {
      session.connect(token.cameraToken, { clientData: userName }).then(() => {
        console.log('connectToSession');
        console.log('selected Camera', selectedCamera);
        console.log('devices', devices);
        console.log('selected Audio', selectedMic);

        let publisher = OV.initPublisher('publisher', {
          insertMode: 'APPEND',
          width: '100%',
          height: '100%',
          audioSource: selectedMic, // The source of audio. If undefined default microphone
          videoSource: selectedCamera, // The source of video. If undefined default webcam
        });
        publisher.addVideoElement(document.getElementById('video-element'));

        localUser.setStreamManager(publisher);
        localUser.setConnectionId(session.connection.connectionId);
        localUser.setType('local');
        localUser.setNickname(userName);
        setLocalUser(localUser);
        session.publish(publisher);
      });
    } else {
      console.log('token is null');
      //Get token from API server
      fetch('https://dev-api.opentring.io/sessions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          sessionId: sessionId,
          nickname: userName,
        }),
      })
        .then(response => {
          console.log('response', response);

          return response.json();
        })
        .then(token => {
          //Connect to session with token
          console.log('token', token);
          setToken(token);
          session
            .connect(token.cameraToken, { clientData: userName })
            .then(() => {
              console.log('connectToSession');
              let publisher = OV.initPublisher('publisher', {
                insertMode: 'APPEND',
                width: '100%',
                height: '100%',
                audioSource: selectedMic, // The source of audio. If undefined default microphone
                videoSource: selectedCamera, // The source of video. If undefined default webcam
              });
              localUser.setStreamManager(publisher);
              localUser.setConnectionId(session.connection.connectionId);
              localUser.setType('local');
              localUser.setNickname(userName);
              setLocalUser(localUser);
              session.publish(publisher);
            });
        });
    } //end of else
  } //end of connectToSession

  useEffect(() => {
    // if(OV != null && session != null)
    // {
    //   subscribeToStreamCreated();
    //   connectToSession()
    // }

    return () => {};
  }, [OV != null, session]);

  useEffect(() => {
    console.log('route', route);
    console.log('route', route);
    console.log('state', llocaion);

    return () => {
      // setSessionId(llocaion.state.sessionId);
    };
  }, [llocaion]);

  const handleJoinMeeting = async () => {
    //get the user's username
    const user = await Auth.currentAuthenticatedUser();
    const username = user.username;
    console.log(user);
    if (OV != null && session != null) {
      subscribeToStreamCreated();
      connectToSession();
    }
  };

  const handleCancel = () => {
    setSessionCreated(false);
    setMeetingName('');
    setMeetingDescription('');
    navigate('/hostmeeting');
  };

  return (
    <Grid>
      <Column lg={16} className="host-meetng__banner">
        <h1 className="host-meetng__heading">Join Meeting</h1>
      </Column>
      <Column lg={8} className="host-meetng__r2">
        {sessionCreated && (
          <FormGroup legendText="Meeting Details">
            <Stack gap={7}>
              <TextInput
                id="meeting-name"
                labelText="Meeting Title"
                placeholder="Enter title or description of meeting"
                value={meetingName}
                disabled
              />
              <TextArea
                id="meeting-description"
                labelText="Meeting Description"
                placeholder="Enter a description of the meeting"
                value={meetingDescription}
                disabled>
                {meetingDescription}
              </TextArea>
              <TextInput
                id="sessionid"
                labelText="Session ID"
                placeholder="Enter title or description of meeting"
                value={sessionId}
              />
              <Stack type="horizontal">
                <Button
                  onClick={handleJoinMeeting}
                  renderIcon={GroupPresentation}>
                  Join Meeting
                </Button>
                <Button onClick={handleCancel} renderIcon={ResetAlt}>
                  Cancel
                </Button>
              </Stack>
            </Stack>
          </FormGroup>
        )}
        {!sessionCreated && (
          <FormGroup legendText="Meeting Details">
            <Stack gap={7}>
              <TextInput
                id="meeting-name"
                labelText="Meeting Title"
                placeholder="Enter title or description of meeting"
                value={meetingName}
                disabled
              />
              <TextArea
                id="meeting-description"
                labelText="Meeting Description"
                placeholder="Enter a description of the meeting"
                value={meetingDescription}
                disabled>
                {meetingDescription}
              </TextArea>
              <TextInput
                id="sessionid"
                labelText="Session ID"
                placeholder="Enter title or description of meeting"
                value={sessionId}
              />
              <Stack type="horizontal">
                <Button
                  onClick={handleJoinMeeting}
                  renderIcon={GroupPresentation}>
                  Join Meeting
                </Button>
                <Button onClick={handleCancel} renderIcon={ResetAlt}>
                  Cancel
                </Button>
              </Stack>
            </Stack>
          </FormGroup>
        )}
      </Column>
      <Column lg={8} className="host-meetng__r2">
        {sessionCreated && (
          <FormGroup legendText="Device Settings">
            <Stack gap={7}>
              <TextInput
                id="username"
                labelText="User Name"
                placeholder="Enter your name"
                value={userName}
                disabled
              />
              <Select
                type="select"
                id="select"
                labelText="Select Camera"
                value={selectedCamera}
                onChange={e => {
                  console.log('Camera changed', e.target.value);
                  setSelectedCamera(e.target.value);
                  let publisher = OV.initPublisher('publisher', {
                    insertMode: 'APPEND',
                    width: '100%',
                    height: '100%',
                    audioSource: undefined, // The source of audio. If undefined default microphone
                    videoSource: e.target.value, // The source of video. If undefined default webcam
                  });
                }}>
                <SelectItem key={0} value={''} text={'Select a Camera'} />
                {devices.map(
                  (device, index) =>
                    device.kind === 'videoinput' && (
                      <SelectItem
                        key={index}
                        value={device.deviceId}
                        text={device.label}
                      />
                    )
                )}
              </Select>
              <div id="video-container">
                <div id="video-element" />
                <div id="publisher" />
                <div id="subscriber" />
              </div>
            </Stack>
          </FormGroup>
        )}
      </Column>
    </Grid>
  );
};

export default JoinMeeting;
// use the navigation prop to navigate to the next screen
