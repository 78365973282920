// Write a react function component called SessionInput

// It should have a text input for the session id and nick name to join the session and a button to join the session

import {
  Button,
  Column,
  FormGroup,
  Grid,
  Select,
  SelectItem,
  Stack,
  TextInput,
} from '@carbon/react';
import { useNavigate } from 'react-router';
import { VideoChat } from '@carbon/react/icons';
import { useEffect, useState } from 'react';
import { OpenVidu } from 'openvidu-browser';
import { Auth } from 'aws-amplify';

const SessionInput = () => {
  const navigate = useNavigate();
  const [selectedCamera, setSelectedCamera] = useState();
  const [selectedMic, setSelectedMic] = useState();
  const [devices, setDevices] = useState([]);
  const [OV, setOV] = useState(null);
  const [publisher, setPublisher] = useState(null);
  const [sessionId, setSessionId] = useState('');
  const [nickName, setNickName] = useState('');

  useEffect(() => {
    let ov = new OpenVidu({});
    setOV(ov);
    // ov.getUserMedia()
    // navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia;
    navigator.mediaDevices
      .getUserMedia({
        audio: true,
        video: true,
      })
      .then(stream => {
        let audioTracks = stream.getAudioTracks();
        let videoTracks = stream.getVideoTracks();
        let defaultVideoDevice = videoTracks[0].getSettings().deviceId;
        let defaultAudioDevice = audioTracks[0].getSettings().deviceId;

        let pub = ov.initPublisher('publisher', {
          insertMode: 'APPEND',
          width: '100%',
          height: '100%',
          audioSource: defaultAudioDevice || undefined, // The source of audio. If undefined default microphone
          videoSource: defaultVideoDevice || undefined, // The source of video. If undefined default webcam
        });
        setPublisher(pub);
        setSelectedMic(defaultAudioDevice);
        setSelectedCamera(defaultVideoDevice);

        navigator.mediaDevices.enumerateDevices().then(devices => {
          // console.log("Media Devices", devices);
          setDevices(devices);
        });
        // ov.getDevices().then((devices) => {
        //   console.log("Devices >>", devices);
        //   setDevices(devices);
        // } ).catch((err) => {
        //   console.log("Error", err);
        // });
        //
      })
      .catch(err => {
        console.log('Error', err);
      });

    // getDevices(ov).then(() => {
    //   console.log("Devices >>", devices);
    // });

    (async () => {
      const user = await Auth.currentAuthenticatedUser();
      const username = user.username;
      setNickName(username);
    })();

    return () => {
      console.log('Unloading Session Input');
      ov = null;
    };
  }, []);

  useEffect(() => {
    console.log('OV useeffect', OV);
    if (OV != null) {
      console.log('Publishers ', OV.publishers);
    }
  }, [OV != null, OV]);

  // const getDevices = async (ov) => {
  //   let devices = await ov.getDevices();
  //   setDevices(devices);
  // }
  const handleJoinMeeting = () => {
    navigate('/meeting', {
      state: {
        // publisher: publisher,
        // ov: OV,
        selectedCamera: selectedCamera,
        selectedMic: selectedMic,
        sessionId: sessionId,
        nickName: nickName,
      },
    });
  };

  const handleCamera = e => {
    console.log('Camera changed', e.target.value);
    setSelectedCamera(e.target.value);
    let publisher = OV.initPublisher('local-video-undefined', {
      insertMode: 'REPLACE',
      width: '100%',
      height: '100%',
      audioSource: selectedMic || undefined, // The source of audio. If undefined default microphone
      videoSource: e.target.value || undefined, // The source of video. If undefined default webcam
    });
    // publisher.createVideoElement();
    setPublisher(publisher);

    console.log('OV', OV);
  };

  const handleMic = e => {
    console.log('Mic changed', e.target.value);
    setSelectedMic(e.target.value);
    let publisher = OV.initPublisher('publisher', {
      insertMode: 'APPEND',
      width: '100%',
      height: '100%',
      audioSource: e.target.value, // The source of audio. If undefined default microphone
      videoSource: selectedCamera || undefined, // The source of video. If undefined default webcam
    });
    publisher.createVideoElement();
    publisher.publishAudio(true);
    publisher.publishVideo(true);
    setPublisher(publisher);
  };

  return (
    <Grid>
      <Column lg={16} className="host-meetng__banner">
        <h1 className="host-meetng__heading">Join Meeting Session</h1>
      </Column>
      <Column lg={8} className="host-meetng__r2">
        <FormGroup legendText="">
          <Stack gap={7}>
            <TextInput
              id="session-id"
              labelText="Meeting ID"
              placeholder="Enter Meeting ID"
              type="text"
              onChange={e => setSessionId(e.target.value)}
            />
            <TextInput
              id="nick-name"
              labelText="Display Name"
              placeholder="Enter Nick Name"
              type="text"
              value={nickName}
              onChange={e => setNickName(e.target.value)}
            />
          </Stack>
        </FormGroup>
      </Column>
      <Column lg={8} className="host-meetng__r2">
        <FormGroup legendText="">
          <Stack gap={7}>
            <Select
              type="select"
              id="select"
              labelText="Select Camera"
              value={selectedCamera}
              onChange={handleCamera}>
              <SelectItem key={0} value={''} text={'Select a Camera'} />
              {devices.map(
                (device, index) =>
                  device.kind === 'videoinput' && (
                    <SelectItem
                      key={index}
                      value={device.deviceId}
                      text={device.label}
                    />
                  )
              )}
            </Select>
            <Select
              type="select"
              id="select"
              labelText="Select Microphone"
              value={selectedMic}
              onChange={handleMic}>
              <SelectItem key={0} value={''} text={'Select a Microphone'} />
              {devices.map(
                (device, index) =>
                  device.kind === 'audioinput' && (
                    <SelectItem
                      key={index}
                      value={device.deviceId}
                      text={device.label}
                    />
                  )
              )}
            </Select>
            <Button
              kind="primary"
              tabIndex={0}
              type="submit"
              renderIcon={VideoChat}
              onClick={handleJoinMeeting}>
              Join
            </Button>

            <div id="video-container">
              <div id="publisher" className="publisher" />
              {/*<div id='subscriber' />*/}
            </div>
          </Stack>
        </FormGroup>
      </Column>
    </Grid>
  );
};

export default SessionInput;
