//React screen to show the video conference in full screen

import { useLocation, useNavigate } from 'react-router';
import { useEffect, useState } from 'react';
import { OpenVidu } from 'openvidu-browser';
import { Column, Grid, Layer, Tile, Stack } from '@carbon/react';
import UserModel from '../../models/user-model';

const VideoConference = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { sessionId, nickName } = location.state;
  const { selectedCamera, selectedMic } = location.state;
  const [OV, setOV] = useState(null);
  const [publisher, setPublisher] = useState(null);
  const [session, setSession] = useState(null);
  const [tokens, setTokens] = useState(null);
  const [subscribers, setSubscribers] = useState([]);
  const [localUser, setLocalUser] = useState(new UserModel());

  // Get the session token from the backend server in useEffect
  useEffect(() => {
    if (OV == null) {
      let ov = new OpenVidu();
      setOV(ov);
    }

    if (OV != null) {
      // let session = OV.initSession();
      // setSession(session);

      getToken().then(tokens => {
        console.log('Tokens >>', tokens);
        connectToSession(tokens).then(ses => {
          console.log('Connected to the session completed', ses);
          setSession(ses);
          subscribeToStreamCreated(ses);
          let pub = OV.initPublisher('publisher', {
            insertMode: 'APPEND',
            width: '100%',
            height: '100%',
            audioSource: selectedMic || undefined, // The source of audio. If undefined default microphone
            videoSource: selectedCamera || undefined, // The source of video. If undefined default webcam
          });

          ses.on('connectionCreated', event => {
            console.log('Connection created', event);
            console.log('Connection created', event.connection);
            localUser.setStreamManager(publisher);
            localUser.setConnectionId(ses.connection.connectionId);
            localUser.setType('local');
            localUser.setNickname(nickName);
            setLocalUser(localUser);
            setPublisher(pub);
            // session.publish(pub);

            ses
              .publish(pub)
              .then(() => {
                console.log('Publisher published');
              })
              .catch(err => {
                console.log('Error publishing publisher', err);
              });
          });
        });
      });
    }
  }, [OV]);

  // useEffect(() => {
  //   if(session != null && tokens != null && publisher != null) {
  //     subscribeToStreamCreated(session);
  //     // session.publish(publisher);
  //   }
  //   return () => {
  //     if (session) {
  //       session.disconnect();
  //     }
  //   };
  // }, [session, tokens, publisher]);

  const getToken = async () => {
    // Get the session token from the backend server
    const res = await fetch('https://dev-api.opentring.io/sessions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        sessionId: sessionId,
        nickName: nickName,
      }),
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Something went wrong');
        }
      })
      .then(tokens => {
        console.log('Tokens', tokens);
        setTokens(tokens);
        return tokens;
      })
      .catch(error => {
        console.log('Error', error);
      });
    console.log('Hello from getToken', res);
    return res;
  };

  const subscribeToStreamCreated = session => {
    console.log('Subscribing to stream created', session);
    session.on('streamCreated', event => {
      console.log('streamCreated', event);
      let subscriber = session.subscribe(event.stream, 'subscriber', {
        insertMode: 'APPEND',
        width: '100%',
        height: '100%',
      });
      const newUser = new UserModel();
      newUser.setStreamManager(subscriber);
      newUser.setConnectionId(event.stream.connection.connectionId);
      newUser.setType('remote');
      const nickname = event.stream.connection.data.split('%')[0];
      newUser.setNickname(JSON.parse(nickname).clientData);
      setSubscribers([...subscribers, newUser]);
    });
  };
  const connectToSession = async tokens => {
    // Join the session
    let ses = OV.initSession();
    ses
      .connect(tokens.cameraToken, { clientData: nickName })
      .then(sess => {
        console.log('Connected to the session', sess);
      })
      .catch(error => {
        console.log(
          'Error connecting to the session:',
          error.code,
          error.message
        );
      });
    return ses;
  };

  return (
    <Grid>
      <Column lg={16} className="video-conference__r1">
        <Stack orientation="horizontal" gap={2}>
          <div id="subscriber" className="video-conference__tile" />
        </Stack>
      </Column>
      <Column lg={12} md={12} sm={12} xs={12} className="host-meetng__r2">
        <div id="videoContainer" style={{ width: '100%', height: '100%' }}>
          <div id="publisher" style={{ width: '100%', height: '100%' }} />
        </div>
      </Column>
    </Grid>
  );
};

export default VideoConference;
