// components/Login.js
import { useEffect } from 'react';

import {
  Authenticator,
  Flex,
  useAuthenticator,
  View,
} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import { useNavigate, useLocation } from 'react-router';

function Login() {
  const { route } = useAuthenticator(context => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();
  let from = location.state?.from?.pathname || '/';
  useEffect(() => {
    if (route === 'authenticated') {
      navigate(from, { replace: true });
    }
  }, [route, navigate, from]);
  return (
    <Flex
      direction="column"
      justifyContent="center"
      alignItems="center"
      className="auth-wrapper">
      <Authenticator />
    </Flex>
  );
}

export default Login;
