import React, { Component, Text } from 'react';
import './App.scss';

import { Content, Theme } from '@carbon/react';
import MainHeader from './components/MainHeader';

import { Route, Routes } from 'react-router-dom';
import LandingPage from './screens/LandingPage';
import Login from './screens/Login';
import RequireAuth from './components/RequireAuth/RequireAuth';
import { Authenticator } from '@aws-amplify/ui-react';
import HostMeeting from './screens/HostMeeting';
import JoinMeeting from './screens/JoinMeeting';
import SessionInput from './screens/SessionInput';
import VideoConference from './screens/VideoConference';

class App extends Component {
  render() {
    return (
      <>
        <Authenticator.Provider>
          <Theme theme="g10">
            <MainHeader />
          </Theme>
          <Content>
            <Routes>
              <Route
                path="/"
                element={
                  <RequireAuth>
                    <LandingPage />
                  </RequireAuth>
                }
              />
              <Route
                path="/hostmeeting"
                element={
                  <RequireAuth>
                    <HostMeeting />
                  </RequireAuth>
                }
              />
              <Route
                path="/joinmeeting/:sessionId"
                element={
                  <RequireAuth>
                    <JoinMeeting />
                  </RequireAuth>
                }
              />
              <Route
                path="/joinmeeting"
                element={
                  <RequireAuth>
                    <SessionInput />
                  </RequireAuth>
                }
              />
              <Route
                path="/meeting"
                element={
                  <RequireAuth>
                    <VideoConference />
                  </RequireAuth>
                }
              />
              <Route path="/login" element={<Login />} />
            </Routes>
          </Content>
        </Authenticator.Provider>
      </>
    );
  }
}

export default App;
